import React, { useEffect } from "react";
import { updateOrders } from "../services/movieServices";
import { toast } from "react-toastify";

export default function EnsurePay() {
  useEffect(() => {
    const handleUpdateOrders = async () => {
      try {
        await updateOrders();
        window.location = "/playlist";
      } catch (error) {
        toast.error("Failed to update your playlist");
      }
    };

    handleUpdateOrders();
  }, []);
  return (
    <>
      <h1 style={{ margin: "50px" }}>Setting up your playlist, wait...</h1>
    </>
  );
}
