import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { VscClose } from "react-icons/vsc";
import Logo from "../assets/images/logo_test.png";
import { Stream } from "@cloudflare/stream-react";
import Loader from "../elements/Loader";
import * as Sentry from "@sentry/react";

import { connect } from "react-redux";
import {
  fetchMoviesAsync,
  fetchOrderAsync,
} from "../redux/movies/movies.action";
import { selectOrders } from "../redux/movies/movies.selector";
import { getCurrentUser as user } from "../services/authServices";
import {
  selectVertualMovies,
  selectLoadingStatus as loading,
} from "../redux/movies/movies.selector";
import { updateOrderCurrentTime } from "../services/movieServices";
import { createStructuredSelector } from "reselect";
import VerifyToken from "../components/tokenCheck/VerifyToken";

const style = { color: "#21baef", cursor: "pointer" };

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function MoviePlayer({
  fetchMoviesAsync,
  fetchOrderAsync,
  movies,
  loading,
  orders,
}) {
  const videoRef = useRef(null);
  const { movieid, orderid } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);

  //let navigate = useNavigate();
  const handleClose = async () => {
    setLoading(true);
    try {
      await updateOrderCurrentTime({
        _id: orderid,
        currentTime: videoRef.current?.currentTime,
      });
      window.location = "/playlist";
      //navigate(-1);
    } catch (error) {
      setLoading(false);
      Sentry.captureException("MoviePlayer.jsx", error);
    }
  };

  const currentUser = user();
  const movie = movies.find((m) => m._id === movieid);
  const order = orders.find((o) => o._id === orderid);

  const nextPartPrefix = movie?.title.split(" ")[0];
  const nextPartNumber = parseInt(movie?.title.split(" ")[1]) + 1;
  const nextPartTitle = `${nextPartPrefix} ${nextPartNumber}`;

  const nextMovie = movies.find(
    (m) => m.title.toLowerCase() === nextPartTitle.toLowerCase()
  );

  const [videoWidth, videoHeigth] = useWindowSize();

  useEffect(() => {
    if (!currentUser) {
      window.location = "/";
      return;
    }
    fetchMoviesAsync();
    fetchOrderAsync();
  }, [fetchMoviesAsync, fetchOrderAsync]);

  const handleVideoEnded = () => {
    setVideoEnded(true);
  };

  return (
    <>
      {loading || !movie || !order || isLoading ? (
        <Loader />
      ) : order?.email === currentUser?.email ? (
        <div
          style={{
            position: "relative",
            margin: "auto",
            width: videoWidth > 780 ? videoWidth - 150 : videoWidth,
            height: videoHeigth,
          }}
        >
          <div style={{ position: "absolute", zIndex: 1000 }}>
            <VscClose
              style={{ margin: "20px", cursor: "pointer" }}
              size={30}
              onClick={handleClose}
            />
          </div>
          <div>
            <Stream
              responsive
              streamRef={videoRef}
              onEnded={handleVideoEnded}
              current
              poster={movie?.movieBannerPictureURL}
              controls
              src={movie?.videoId}
              currentTime={order?.currentTime}
              startTime={order?.currentTime}
            />
            {videoEnded && nextMovie && (
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  backgroundColor: "#11161f",
                  padding: "10px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                }}
              >
                <img
                  src={
                    nextMovie?.movieBannerPictureURL
                      ? nextMovie.movieBannerPictureURL
                      : nextMovie?.moviePictureURL
                  }
                  alt="Next Video"
                  style={{
                    width: "160px",
                    height: "110px",
                    borderRadius: "5px",
                    objectFit: "cover",
                    padding: "5px",
                  }}
                />
                <button
                  style={{
                    width: "160px",
                    padding: "8px",
                    backgroundColor: "#21baef",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    (window.location = `/moviedetails/${nextMovie?._id}`)
                  }
                >
                  Watch Next
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div id="loader" className="loader">
          <div style={{ color: "white", margin: "auto" }}>
            <img src={Logo} alt="default" width={150} height={35} />
            <br />
            <br />
            <div>
              <h4>You are not allowed to watch this movie.</h4>
              <br />
              <h4>
                Go to{" "}
                <span style={style} onClick={() => (window.location = "/")}>
                  Home Page{" "}
                </span>
                .
              </h4>
            </div>
          </div>
        </div>
      )}
      {currentUser && <VerifyToken />}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
});

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  orders: selectOrders,
  loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(MoviePlayer);
